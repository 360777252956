<template>
  <Dialog v-model:visible="display" header='选择系统语言' @hide="$emit('close')" :modal="true">
    <div class="content">
      <div style="margin-top:1rem" class="field-radiobutton" v-for="(v,i) in list" :key="i">
        <RadioButton :inputId="'language'+i" name="language" :value="v.label" v-model="language" />&nbsp;&nbsp;
        <label :for="'language'+i">{{v.label}}</label>
      </div>
      <!-- <p v-for="(v,i) in list" :key="i">{{v.label}}</p> -->
    </div>
    <template #footer>
      <Button @click="submit">确认选择</Button>
    </template>
  </Dialog>
</template>


<script>
export default {
  name: "addOrUpdatesuggest1",
  data() {
    return {
      display: false,
      list: [],
      language: null,
    };
  },
  methods: {
    init(data) {
      this.display = true;
      this.list = data;
    },
    submit() {
      let val = this.language;
      this.$emit("sysLang", val);
      this.display = false;
    },
  },
};
</script>

<style scoped>
.content {
  width: 20vw;
  height: 30vh;
}

.colee {
  display: flex;
  justify-content: flex-start;
}

::v-deep(.p-dialog .p-dialog-content) {
  padding-top: 0;
}

.rig {
  margin-right: 2vw;
}
</style>
